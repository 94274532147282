/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react";
 import { ParallaxProvider } from "react-scroll-parallax";
 import Helmet from "react-helmet";
 import Footer from "../components/footer";
 import Scrollup from "../components/scrollup";
 
 import { CookiesProvider } from "react-cookie";
 import languages from "../../data/languages.json";
 
 const Layout = (props) => {
   return (
     <CookiesProvider>
       <ParallaxProvider>
         <Helmet>
           <link
             rel="stylesheet"
             type="text/css"
             href="https://use.typekit.net/fua6sah.css"
           />
 
           {languages.map((lang, i) => {
             return (
               <link
                 rel="alternate"
                 href={
                   "https://www.dalytrials.com/" +
                   (lang.short === "en" ? "" : lang.url + "/") +
                   (props.url !== "/" ? props.url : "")
                 }
                 hreflang={lang.short}
                 key={lang.short}
               />
             );
           })}
         </Helmet>
         {props.children}
         <Scrollup />
         <Footer className={props.footer} site={props.site} i18n={props.i18n} />
       </ParallaxProvider>
     </CookiesProvider>
   );
 };
 
 export default Layout;
 