import React from "react";

const Menu = () => {
  const [open] = React.useState(false);
  function onClick() {
    scrollTo(0, 1250);
  }

  function scrollTo(to, duration) {
    var start = document.documentElement.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      var easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      document.documentElement.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  return (
    <div
      className={`scrollup ${open ? "is-open" : ""}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <svg
        className=""
        enableBackground="new 0 0 50 50"
        height="50px"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 50 50"
        width="50px"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect fill="none" height="50" width="50" />
        <polygon points="15,2.75 12.914,4.836 33.078,25 12.914,45.164 15,47.25 37.25,25 " />
      </svg>
    </div>
  );
};

export default Menu;
