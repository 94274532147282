import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";

const versionConfig = {
  version1: {
    morphingbubble__img: require("../images/backgrounds/footage-1-square.jpg"),
    color: "#3C1192",
    path: {
      mouseout:
        "M340.087478,398.830555 C422.066491,350.882373 359.019622,311.853606 383.933891,236.20074 C408.84816,160.547874 314.025932,67.4510255 242.352781,106.007077 C170.679629,144.563129 93.4082278,114.085134 97.1292878,207.69754 C98.7738922,249.071605 131.838559,348.66112 188.318551,379.263066 C237.582909,405.955441 258.108466,446.778737 340.087478,398.830555 Z",
      mousein:
        "M353.070313,453.136719 C465.048202,424.414063 423,327.429687 413.863281,239.806483 C404.726563,152.183279 464.859375,104.609375 383.707031,65.3046875 C302.554688,26 122.813517,85.0170463 65.8909903,155.622953 C30.1784417,199.920299 46.7396614,380.289062 94.8568489,421 C138.812593,458.190028 241.092423,481.859375 353.070313,453.136719 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M274.959778,487.724587 C328.153747,500.58262 401.287511,413.608469 434.210352,344.463351 C460.304838,289.659262 445.101062,230.073115 449.571093,177.226079 C452.513406,142.440531 403.403075,82.5512046 353.713438,40.5564784 C298.784895,-5.86585852 217.28588,73.747942 146.357376,71.9699706 C53.1643498,69.6338925 54.1977417,125.869499 51.1618276,158.826916 C44.6607807,229.401283 66.7698938,294.319315 84.4790877,341.919675 C97.3300077,376.461531 179.604523,464.675338 274.959778,487.724587 Z",
    },

    clippath: "clipShape1",
    small: false,
  },
  version2: {
    morphingbubble__img: require("../images/backgrounds/footage-3-square.jpg"),
    color: "#119BC2",
    path: {
      mouseout:
        "M347.798239,374.185256 C435.874351,374.185256 421.714558,276.763744 367.285268,210.2305 C312.855979,143.697255 336.556075,106.886991 266.521754,97.4882741 C196.487433,88.0895576 162.335474,123.535665 128.46425,194.497268 C111.323283,230.408295 99.4082548,284.533518 153.101267,313.726658 C199.934697,339.190212 224.555465,392.692883 347.798239,374.185256 Z",
      mousein:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.527405779999995 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.660711199999994,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.52740578 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.6607112,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    clippath: "clipShape02",
    small: {
      
      morphingbubble__img: require("../images/backgrounds/footage-3-square-small.jpg"),
      path: {
        mouseout:
          "m196.597959,207.833858c-31.011684,-47.808534 -136.447424,-60.380243 -76.400689,-124.505831c67.046735,-52.125587 129.568934,-14.5561 204.610995,19.732253c75.042061,34.288353 218.587628,111.022446 116.508179,169.802279c-102.079449,58.779833 -213.706801,-17.220167 -244.718485,-65.028701z",
        mousein:
          "m213.597959,330.833858c-31.011684,-47.808534 -158.447424,-168.380243 -98.400689,-232.505831c67.046735,-52.125587 133.568934,37.4439 208.610995,71.732253c75.042061,34.288353 215.587628,61.022446 113.508179,119.802279c-102.079449,58.779833 -192.706801,88.779833 -223.718485,40.971299z",
      },
    },
  },
  version3: {
    morphingbubble__img: require("../images/backgrounds/hands.jpg"),
    color: "#3C1192",
    path: {
      mouseout:
        "M347.798239,374.185256 C435.874351,374.185256 421.714558,276.763744 367.285268,210.2305 C312.855979,143.697255 336.556075,106.886991 266.521754,97.4882741 C196.487433,88.0895576 162.335474,123.535665 128.46425,194.497268 C111.323283,230.408295 99.4082548,284.533518 153.101267,313.726658 C199.934697,339.190212 224.555465,392.692883 347.798239,374.185256 Z",
      mousein:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.527405779999995 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.660711199999994,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    img: {
      scalex: 1.4,
      scaley: 1.4,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.52740578 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.6607112,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    clippath: "clipShape02",
    small: false,
  },
  version4: {
    morphingbubble__img: require("../images/backgrounds/hands.jpg"),
    color: "#3C1192",
    path: {
      mouseout:
        "M347.798239,374.185256 C435.874351,374.185256 421.714558,276.763744 367.285268,210.2305 C312.855979,143.697255 336.556075,106.886991 266.521754,97.4882741 C196.487433,88.0895576 162.335474,123.535665 128.46425,194.497268 C111.323283,230.408295 99.4082548,284.533518 153.101267,313.726658 C199.934697,339.190212 224.555465,392.692883 347.798239,374.185256 Z",
      mousein:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.527405779999995 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.660711199999994,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.5,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.52740578 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.6607112,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    clippath: "clipShape04",
    small: false,
  },
  version5: {
    morphingbubble__img: require("../images/backgrounds/footage-1-square.jpg"),
    color: "#3C1192",
    path: {
      mouseout:
        "M347.798239,374.185256 C435.874351,374.185256 421.714558,276.763744 367.285268,210.2305 C312.855979,143.697255 336.556075,106.886991 266.521754,97.4882741 C196.487433,88.0895576 162.335474,123.535665 128.46425,194.497268 C111.323283,230.408295 99.4082548,284.533518 153.101267,313.726658 C199.934697,339.190212 224.555465,392.692883 347.798239,374.185256 Z",
      mousein:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.527405779999995 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.660711199999994,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.52740578 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.6607112,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    clippath: "clipShape05",
    small: false,
  },
  version6: {
    morphingbubble__img: require("../images/backgrounds/footage-1-square.jpg"),
    color: "#3C1192",
    path: {
      mouseout:
        "M347.798239,374.185256 C435.874351,374.185256 421.714558,276.763744 367.285268,210.2305 C312.855979,143.697255 336.556075,106.886991 266.521754,97.4882741 C196.487433,88.0895576 162.335474,123.535665 128.46425,194.497268 C111.323283,230.408295 99.4082548,284.533518 153.101267,313.726658 C199.934697,339.190212 224.555465,392.692883 347.798239,374.185256 Z",
      mousein:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.527405779999995 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.660711199999994,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M257.680426,447.032314 C377.193909,533.50259 477.798161,282.226661 443.034833,236.547748 C408.271505,190.868836 436.431342,99.6102221 335.06509,48.1183709 C253.189606,6.52740578 119.297864,80.6696323 84.8014769,139.383802 C50.3050903,198.097972 29.6607112,331.989558 80.5693366,359.736553 C131.477962,387.483549 138.166942,360.562039 257.680426,447.032314 Z",
    },
    clippath: "clipShape06",
    small: false,
  },
  version7: {
    morphingbubble__img: require("../images/backgrounds/patient-cartcell-01-orig.jpg"),
    color: "#3C1192",
    path: {
      mouseout:
        "M 278.839 402.001 C 349.84 351.697 439.582 352.534 436.773 241.896 C 443.85 178.043 376.471 89.15 319.13 87.524 C 258.675 84.015 268.878 90.107 199.631 122.687 C 167.422 137.841 101.284 155.358 105.211 228.749 C 109.377 306.583 229.88 465.062 278.839 402.001 Z",
      mousein:
        "M 287.096 459.703 C 407.272 460.672 470.812 338.433 473.982 261.672 C 480.894 161.81 383.298 103.943 296.551 58.07 C 225.284 20.383 -10.844 -22.478 -18.17 81.65 C -26.802 204.33 116.143 226.713 124.148 270.826 C 140.732 362.203 154.841 447.726 287.096 459.703 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M 182.531 432.95 C 302.044 519.42 449.651 381.289 453.123 290.027 C 456.595 198.765 436.03 81.387 335.065 48.118 C 247.845 19.378 220.243 89.426 156.61 81.042 C 92.977 72.658 44.56 179.643 75.184 253.068 C 105.808 326.493 63.017 346.48 182.531 432.95 Z",
    },

    clippath: "clipShape5",
    small: false,
  },
  trialseu: {
    morphingbubble__img: require("../images/backgrounds/bubble/eu-rectangle@2x-pins.png"),
    color: "#119BC2",
    path: {
      mouseout:
        "M 266.679 378.816 C 343.315 358.201 401 364 432 311 C 439 299 436 103 356 139 C 249 189 249 151 166 161 C 111 166 105 282 126 309 C 156 348 143.436 397.324 266.679 378.816 Z",
      mousein:
        "M 344 358 C 343.315 358.201 386 373 400 264 C 401 203 657 56 347 56 C 182 62 260 112 83 163 C -50 176 105 282 126 309 C 156 348 237 354 283 354 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M 201.774 403.74 C 337.684 461.093 418 461 453 391 C 471 197 446 89 383.756 46.239 C 301.881 4.648 252 112 177 102 C 41 117 1 270 49 341 C 117 429 127.277 372.303 201.774 403.74 Z",
    },

    clippath: "clipShape4eu",
    small: false,
  },
  trialsus: {
    morphingbubble__img: require("../images/backgrounds/bubble/us-rectangle@2x-pins.png"),
    color: "#119BC2",
    path: {
      mouseout:
        "M 263 339 C 353 337 439.582 352.534 436.773 241.896 C 443.85 178.043 447 121 334 145 C 229 151 261 107 162 142 C 61 160 63 227 65 272 C 74 335 119 354 243 341 Z",
      mousein:
        "M 262 390 C 355 398 420 305 436.773 241.896 C 473 145 497 9 337 20 C 253 32 9 22 -13 93 C -48 250 63 227 65 272 C 74 335 158 411 247 390 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M 226 398 C 380 395 449.651 381.289 453.123 290.027 C 456.595 198.765 550 60 335.065 48.118 C 190 45 220.243 89.426 156.61 81.042 C 108 77 25 99 25 242 C 32 383 124 383 186 392 Z",
    },

    clippath: "clipShape5us",
    small: false,
  },
  
  trialseu_stage: {
    morphingbubble__img: require("../images/backgrounds/bubble/eu-rectangle@2x-pins.png"),
    color: "#119BC2",
    path: {
      mouseout:
        "M 266.679 378.816 C 343.315 358.201 401 364 432 311 C 439 299 436 103 356 139 C 249 189 249 151 166 161 C 111 166 105 282 126 309 C 156 348 143.436 397.324 266.679 378.816 Z",
      mousein:
        "M 344 358 C 343.315 358.201 386 373 400 264 C 401 203 657 56 347 56 C 182 62 260 112 83 163 C -50 176 105 282 126 309 C 156 348 237 354 283 354 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M 201.774 403.74 C 337.684 461.093 418 461 453 391 C 471 197 446 89 383.756 46.239 C 301.881 4.648 252 112 177 102 C 41 117 1 270 49 341 C 117 429 127.277 372.303 201.774 403.74 Z",
    },

    clippath: "clipShape4eu2",
    small: false,
  },
  trialsus_stage: {
    morphingbubble__img: require("../images/backgrounds/bubble/us-rectangle@2x-pins.png"),
    color: "#119BC2",
    path: {
      mouseout:
        "M 263 339 C 353 337 439.582 352.534 436.773 241.896 C 443.85 178.043 447 121 334 145 C 229 151 261 107 162 142 C 61 160 63 227 65 272 C 74 335 119 354 243 341 Z",
      mousein:
        "M 262 390 C 355 398 420 305 436.773 241.896 C 473 145 497 9 337 20 C 253 32 9 22 -13 93 C -48 250 63 227 65 272 C 74 335 158 411 247 390 Z",
    },
    img: {
      scalex: 1.3,
      scaley: 1.3,
      translatex: 0,
      translatey: 0,
      rotate: 0,
    },
    deco: {
      scalex: 1.1,
      scaley: 1.0,
      translatex: -5,
      translatey: -5,
      rotate: -10,
      d:
        "M 226 398 C 380 395 449.651 381.289 453.123 290.027 C 456.595 198.765 550 60 335.065 48.118 C 190 45 220.243 89.426 156.61 81.042 C 108 77 25 99 25 242 C 32 383 124 383 186 392 Z",
    },

    clippath: "clipShape5us2",
    small: false,
  },
};

const MorphingBubble = (props) => {
  const [isActive, setActive] = React.useState(false);

  var mouseTimeout = null;
  // export default class MorphingBubble extends Component {

  function animation(active) {

    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if(isIE11) {
      return true;
    }
    // let active = isActive;
    // console.log("active: ", active);
    const elm = `.morphingbubble--${props.id} .morphingbubble__svg--large`;
    anime({
      targets: elm + " .morphingbubble__deco",
      duration: 1300,
      delay: 0,
      easing: "easeOutQuad",
      elasticity: 300,
      scaleX: active ? versionConfig[props.id].deco.scalex : 1,
      scaleY: active ? versionConfig[props.id].deco.scaley : 1,
      translateX: active ? versionConfig[props.id].deco.translatex : 0,
      translateY: active ? versionConfig[props.id].deco.translatey : 0,
      rotate: active ? versionConfig[props.id].deco.rotate : 0,
    });
    anime({
      targets: elm + " .morphingbubble__img",
      duration: 800,
      delay: 0,
      easing: "easeInOutQuart",
      elasticity: 300,
      scaleX: active ? versionConfig[props.id].img.scalex : 1,
      scaleY: active ? versionConfig[props.id].img.scaley : 1,
      translateX: active ? versionConfig[props.id].img.translatex : 0,
      translateY: active ? versionConfig[props.id].img.translatey : 0,
      rotate: active ? versionConfig[props.id].img.rotate : 0,
    });
    anime({
      targets: elm + " .morphingbubble__clippath",
      duration: 800,
      delay: 0,
      easing: "easeInOutCubic",
      elasticity: 300,
      scaleX: active ? 0.8 : 1,
      scaleY: active ? 0.9 : 1,
      translateX: active ? 0 : 0,
      translateY: active ? 30 : 0,
      rotate: active ? 5 : 0,
      d: active
        ? [
            {
              value: versionConfig[props.id].path.mousein,
            },
          ]
        : [
            {
              value: versionConfig[props.id].path.mouseout,
            },
          ],
    });

    if (versionConfig[props.id].small) {
      const elmSmall = `.morphingbubble--${props.id} .morphingbubble__svg--small`;
      anime({
        targets: elmSmall + " .morphingbubble__img",
        duration: 800,
        delay: 0,
        easing: "easeInOutQuart",
        elasticity: 300,
        scaleX: active ? versionConfig[props.id].img.scalex : 1,
        scaleY: active ? versionConfig[props.id].img.scaley : 1,
        translateX: active ? versionConfig[props.id].img.translatex : 0,
        translateY: active ? versionConfig[props.id].img.translatey : 0,
        rotate: active ? versionConfig[props.id].img.rotate : 0,
      });
      anime({
        targets: elmSmall + " .morphingbubble__clippath",
        duration: 800,
        delay: 0,
        easing: "easeInOutCubic",
        elasticity: 300,
        scaleX: active ? 0.8 : 1,
        scaleY: active ? 0.9 : 1,
        translateX: active ? -90 : 0,
        translateY: active ? -60 : 0,
        rotate: active ? -4 : 0,
        d: active
          ? [
              {
                value: versionConfig[props.id].small.path.mousein,
              },
            ]
          : [
              {
                value: versionConfig[props.id].small.path.mouseout,
              },
            ],
      });
    }
  }

  function onVisible(isVisible) {
    // console.log("Element is now %s", isVisible ? "visible" : "hidden");
    if (window.innerWidth < 992) {
      setActive(isVisible);
      animation(isVisible);
    }
  }

  return (
    <VisibilitySensor
      onChange={onVisible}
      partialVisibility={false}
      minTopValue={{ top: 0 }}
    >
      <div>
        <div
          className={`morphingbubble morphingbubble--${props.id}`}
          onMouseOver={(e) => {
            mouseTimeout = setTimeout(() => {
              setActive(true);
              animation(true);
            }, 150);
          }}
          onFocus={() => {
            mouseTimeout = setTimeout(() => {
              setActive(true);
              animation(true);
            }, 150);
          }}
          onMouseOut={(e) => {
            clearTimeout(mouseTimeout);
            if (isActive) {
              setActive(false);
              animation(false);
            }
          }}
          onBlur={() => {
            clearTimeout(mouseTimeout);
            if (isActive) {
              setActive(false);
              animation(false);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <svg
            className="morphingbubble__svg morphingbubble__svg--large"
            width="500px"
            height="500px"
            viewBox="0 0 500 500"
          >
            <clipPath id={`${versionConfig[props.id].clippath}`}>
              <path
                className="morphingbubble__clippath"
                d={versionConfig[props.id].path.mouseout}
              />
            </clipPath>
            <g className="morphingbubble__deco">
              <path d={versionConfig[props.id].deco.d}></path>
            </g>
            <g clipPath={`url(#${versionConfig[props.id].clippath})`}>
              <image
                className="morphingbubble__img"
                xlinkHref={versionConfig[props.id].morphingbubble__img}
                x="0"
                y="0"
                height="500px"
                width="500px"
              />
              <polygon
                stroke="transparent"
                fill={versionConfig[props.id].color}
                opacity="0.20"
                points="0 0 500 0 500 500 0 500"
              ></polygon>
            </g>
          </svg>
          {versionConfig[props.id].small && (
            <svg
              className="morphingbubble__svg2 morphingbubble__svg--small"
              width="500px"
              height="500px"
              viewBox="0 0 500 500"
            >
              <clipPath id={`${versionConfig[props.id].clippath}small`}>
                <path
                  className="morphingbubble__clippath"
                  d={versionConfig[props.id].small.path.mouseout}
                />
              </clipPath>

              <g clipPath={`url(#${versionConfig[props.id].clippath}small)`}>
                <image
                  className="morphingbubble__img"
                  xlinkHref={versionConfig[props.id].small.morphingbubble__img}
                  x="-100"
                  y="-100"
                  height="570px"
                  width="620px"
                />
                <polygon
                  stroke="transparent"
                  fill={versionConfig[props.id].color}
                  opacity="0.20"
                  points="0 0 500 0 500 500 0 500"
                ></polygon>
              </g>
            </svg>
          )}
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default MorphingBubble;
