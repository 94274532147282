import React from "react";
import { Link } from "gatsby";
import Header from "../components/header";
import Menu from "../components/menu";
import Cookie from "../components/cookie";
import JSON from "../../data/locations.json";
import MorphingBubble from "../components/morphingbubble";


const Stage = (props) => {

  
  function onClick() {
    document.body.className = document.body.className.replace(
      "locations-is-open",
      ""
    );
  }

  return (
    <React.Fragment>
      <Menu  i18n={props.i18n} site={props.site || null}></Menu>
      <Cookie i18n={props.i18n} />
      <div className={`stage d-flex ${props.className || null}`}>
        <div className="container position-relative">
          <Header site={props.site || null} i18n={props.i18n} />
          <div className="stage__locations">
          
            <div
              className="stage__locations-close"
              onClick={onClick}
              onKeyDown={onClick}
              role="button"
              tabIndex={0}
            >
              <svg
                enableBackground="new 0 0 100 100"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 100 100"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <polygon
                  fill="#010101"
                  points="77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 "
                />
              </svg>
            </div>
            
            <div className="container">
              <div className="row">
                
                <div className="col-12">

                  <div className="d-flex">
                    <div className="stage__locations-map mr-5">
                      <MorphingBubble id="trialseu_stage" />
                    </div>
                    <div>
                      <p
                        className="util-h2 util-h1--xs util-color-white font-weight-bold"
                        dangerouslySetInnerHTML={{ __html: JSON[props.i18n].title1 }}
                      ></p>
                      <Link to={(props.i18n !== "en" ? "/" + props.i18n : "") + "/clinical-trial/eu"}  onClick={onClick} className="btn btn--outlined">
                        {JSON[props.i18n].button1}
                      </Link>
                    </div>
                  </div>


                  <div className="d-flex justify-content-end">
                    
                    <div>
                      <p
                        className="util-h2 util-h1--xs util-color-white font-weight-bold"
                        dangerouslySetInnerHTML={{ __html: JSON[props.i18n].title2 }}
                      ></p>
                      <Link to={(props.i18n !== "en" ? "/" + props.i18n : "") + "/clinical-trial/us"}  onClick={onClick} className="btn btn--outlined">
                        {JSON[props.i18n].button2}
                      </Link>
                    </div>
                    <div className="stage__locations-map ml-5">
                      <MorphingBubble id="trialsus_stage" />
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Stage;
