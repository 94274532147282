import React, { useEffect } from "react";
import JSON from "../../data/cookie.json";
import JSONGlobal from "../../data/global.json";
import Readmore from "../components/readmore";
import { useCookies } from "react-cookie";
import { Link } from "gatsby";
import TagManager from "react-gtm-module";
const cookieGTM = "mb-gtm-allowed",
  cookieConsent = "mb-consent-21001",
  cookieStatistic = "mb-consent-statistic-21001",
  gtmID = "GTM-NLDGXXP"; // Debug: GTM-NBZCF87   Live: GTM-TJ2HPQZ

const Cookie = (props) => {
  const [statisticChecked, setStatistic] = React.useState(false);
  const [cookies, setCookie] = useCookies([cookieConsent]);

  useEffect(() => {
    checkVisit();
  }, []);

  function checkVisit() {
    if (!cookies[cookieConsent]) {
      openWindow();
    }
    // check Statistics
    if (cookies[cookieStatistic] === "true") {
      initGTMTracking();
      setStatistic(true);
    }
  }

  function openWindow() {
    document.body.classList.add("cookie-is-open");
  }
  function closeWindow() {
    document.body.className = document.body.className.replace(
      "cookie-is-open",
      ""
    );
  }

  function onClickStatistic(e) {
    e.preventDefault();
    setStatistic(!statisticChecked);
  }

  function onClickConfirm(e) {
    e.preventDefault();

    setStatisticsTracking(statisticChecked);

    setCookie(cookieConsent, true, {
      path: "/",
      expires: new Date(Date.now() + 31536000000),
    });
    closeWindow();
  }

  function onClickAll(e) {
    e.preventDefault();
    setCookie(cookieConsent, true, {
      path: "/",
      expires: new Date(Date.now() + 31536000000),
    });
    setStatisticsTracking(true);
    closeWindow();
  }

  function setStatisticsTracking(state) {
    if (state) {
      setCookie(cookieStatistic, true, {
        path: "/",
        expires: new Date(Date.now() + 31536000000),
      });
      initGTMTracking();
    } else {
      setCookie(cookieStatistic, false, {
        path: "/",
        expires: new Date(Date.now() + 31536000000),
      });
    }
  }

  function initGTMTracking() {
    // console.log("initGTMTracking ", gtmID);
    const tagManagerArgs = {
      gtmId: gtmID,
    };
    TagManager.initialize(tagManagerArgs);
  }

  return (
    <div className="cookie">
      <h3 className="mt-0">{JSON[props.i18n].title}</h3>

      <p
        dangerouslySetInnerHTML={{
          __html: JSON[props.i18n].text,
        }}
      ></p>
      <div className="cookie__inner mt-lg-5 mt-0">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-5  col-xxl-5 mb-4 mb-lg-0">
            <div className="cookie__checkbox">
              <div className="cookie__checkmark"></div>
              <span className="pl-3 util-f-1">{JSON[props.i18n].technical}</span>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-5   col-xxl-3">
            <div className="cookie__checkbox">
              <div
                className={`cookie__checkmark-wrapper ${
                  statisticChecked ? "is-active" : ""
                }`}
                
                onClick={onClickStatistic}
              >
                <div className="cookie__checkmark"></div>
              </div>
              <span className="pl-3 util-f-1">{JSON[props.i18n].statistics}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Readmore
              showLess={JSONGlobal[props.i18n].showless}
              showMore={JSONGlobal[props.i18n].showmore}
            >
              {JSON[props.i18n].info.map((item, i) => {
                return (
                  <div key={i} className="mb-2">
                    <p className="font-weight-bold mb-1 ">{item.title}</p>
                    <p className="">{item.text}</p>
                  </div>
                );
              })}
            </Readmore>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center text-xl-right d-flex justify-content-between w-199 flex-column flex-xl-row">
            <Link
              to={(props.i18n !== "en" ? "/" + props.i18n : "") + "/legal"}
              className="util-color-tertiary  mb-4 mb-xl-0 mt-xl-4 d-block"
            >
              {JSONGlobal[props.i18n].menu.imprint}
            </Link>
            <span>
              <button
                className="btn mr-lg-3 mb-3 mb-xl-0 btn--primary"
                onClick={onClickConfirm}
              >
                {JSON[props.i18n].selection}
              </button>
              <button className="btn btn--secondary" onClick={onClickAll}>
                {JSON[props.i18n].accept}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
