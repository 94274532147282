import React from "react";
import { Link } from "gatsby";
import JSON from "../../data/global.json";

const Footer = (props) => {
  function scrollTo(to, duration) {
    var start = document.documentElement.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      var easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      document.documentElement.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  // function onLocationsClick(e) {
  //   e.preventDefault();
  //   scrollTo(0, 250);
  //   document.body.classList.add("locations-is-open");
  // }

  function onCookieClick(e) {
    e.preventDefault();
    scrollTo(0, 250);
    document.body.classList.add("cookie-is-open");
  }
  function onLinkClick(name) {
    scrollTo(0, 0);

    var dataLayerConfig = {
      event: "click",
      eventCategory: "FooterNavi",
      eventAction: name, // e.g.: Spaltennamen e.g. Product/Technology/Indicators
      // 'eventLabel' : document.getElementById(id).getAttribute("data-event-label"), // e.g.: Name von  Product/Technology/Indicator
    };
    // console.log("datalayer push: ",dataLayerConfig);
    window.dataLayer.push(dataLayerConfig);
  }

  return (
    <footer className={`footer ${props.className}`}>
      <div className="footer__top">
        {props.i18n == "en" &&
          (props.site === "car-t-therapy" ||
            props.site === "home" ||
            props.site === "clinical-trials" ||
            props.site === "clinical-trials-eu" ||
            props.site === "clinical-trials-us") && (
            <div className="footer__disclaimer ">
              <p
                dangerouslySetInnerHTML={{
                  __html: JSON[props.i18n].disclaimer,
                }}
              ></p>
            </div>
          )}
        <div className="container">
          <ul className="footer__top-links">
            <li
              className={`${
                props.site === "clinical-trials" ? "is-active" : ""
              }`}
            >
              <Link
                onClick={() => onLinkClick("The DALY 2 trials")}
                to={
                  (props.i18n !== "en" ? "/" + props.i18n : "") +
                  "/clinical-trial"
                }
              >
                {JSON[props.i18n].menu.clinicaltrial}
              </Link>
            </li>
            <li
              className={`${props.site === "car-t-therapy" ? "is-active" : ""}`}
            >
              <Link
                onClick={() => onLinkClick("About CAR T therapy")}
                to={
                  (props.i18n !== "en" ? "/" + props.i18n : "") +
                  "/car-t-cell-therapy"
                }
              >
                {JSON[props.i18n].menu.celltherapy}
              </Link>
            </li>
          </ul>
          <div className="footer__top-branches">
            <div>
              <Link
                onClick={() => onLinkClick("Miltenyi logo")}
                to="https://www.miltenyibiomedicine.com/"
                target="_blank"
              >
                <img
                  className="lazyload"
                  data-src={require("../images/logos/Logo_MiltenyiBiomed.svg")}
                  alt="Logo Miltenyi Biomedicine"
                />
              </Link>
            </div>
            <div>
              <Link
                onClick={() => onLinkClick("DALY logo")}
                to={props.i18n !== "en" ? "/" + props.i18n : "/"}
              >
                <img
                  className="lazyload"
                  data-src={require("../images/logos/DALY_Study_RGB.svg")}
                  alt="Logo DALY 2"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <ul className="footer__bottom-links">
            <li className={`${props.site === "privacy" ? "is-active" : ""}`}>
              <Link
                onClick={() => onLinkClick("Privacy statement")}
                to={(props.i18n !== "en" ? "/" + props.i18n : "") + "/privacy"}
              >
                {JSON[props.i18n].menu.privacy}
              </Link>
            </li>
            <li className={`${props.site === "legal" ? "is-active" : ""}`}>
              <Link
                onClick={() => onLinkClick("Legal notes")}
                to={(props.i18n !== "en" ? "/" + props.i18n : "") + "/legal"}
              >
                {JSON[props.i18n].menu.legal}
              </Link>
            </li>
            <li className={`${props.site === "legal" ? "is-active" : ""}`}>
              <Link
                onClick={() => onLinkClick("Imprint")}
                to={
                  (props.i18n !== "en" ? "/" + props.i18n : "") +
                  "/legal#provider"
                }
              >
                {JSON[props.i18n].menu.imprint}
              </Link>
            </li>
            <li>
              <a href="!#" onClick={onCookieClick}>
                {JSON[props.i18n].menu.cookies}
              </a>
            </li>
          </ul>
          <p className="footer__bottom-copyright">
            © <nobr>Miltenyi Biomedicine</nobr> 2023
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
