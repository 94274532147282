import React, { useEffect } from "react";
import { Link } from "gatsby";
import JSON from "../../data/global.json";
import Language from "./language";
const headerHeight = 75,
  scrollUpHeight = 800;

var isSticky = false,
  isScrollUp = false;
const Header = (props) => {
  function onClick(e) {
    e.preventDefault();
    scrollTo(0, 250);
    document.body.classList.add("locations-is-open");

    var dataLayerConfig = {
      event: "click",
      eventCategory: "TopNavi",
      eventAction: "Trial locations", // e.g.: Spaltennamen e.g. Product/Technology/Indicators
      // 'eventLabel' : document.getElementById(id).getAttribute("data-event-label"), // e.g.: Name von  Product/Technology/Indicator
    };
    // console.log("datalayer push: ",dataLayerConfig);
    window.dataLayer.push(dataLayerConfig);
  }

  function closeLocations() {
    document.body.className = document.body.className.replace(
      "locations-is-open",
      ""
    );
  }

  function scrollTo(to, duration) {
    var start = document.documentElement.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      var easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      document.documentElement.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  const handleScroll = () => {
    var scrollPosY = window.scrollY;
    if (!scrollPosY) {
      scrollPosY = window.pageYOffset;
    }

    if (!isSticky && scrollPosY > headerHeight) {
      isSticky = true;
      document.body.classList.add("header-is-sticky");
    }
    if (isSticky && scrollPosY <= headerHeight) {
      isSticky = false;
      document.body.className = document.body.className.replace(
        "header-is-sticky",
        ""
      );
    }

    if (!isScrollUp && scrollPosY > scrollUpHeight) {
      isScrollUp = true;
      document.body.classList.add("scrollup-is-active");
    }
    if (isScrollUp && scrollPosY <= scrollUpHeight) {
      isScrollUp = false;
      document.body.className = document.body.className.replace(
        "scrollup-is-active",
        ""
      );
    }
  };

  function onLinkClick(name) {
    var dataLayerConfig = {
      event: "click",
      eventCategory: "TopNavi",
      eventAction: name, // e.g.: Spaltennamen e.g. Product/Technology/Indicators
      // 'eventLabel' : document.getElementById(id).getAttribute("data-event-label"), // e.g.: Name von  Product/Technology/Indicator
    };
    // console.log("datalayer push: ",dataLayerConfig);
    window.dataLayer.push(dataLayerConfig);

    closeLocations();
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  // document.body.classList.add("header-is-sticky");

  return (
    <header className={`header`}>
      <div className="container">
        <div className="header__logo">
          <Link
            onClick={() => onLinkClick("Homepage")}
            to={(props.i18n !== "en" ? "/" + props.i18n : "") + "/"}
          >
            <img
              className="header__logo-bg"
              src={require("../images/layout/header-logo-bg.svg")}
              alt="Background Logo"
            />
            <img
              className="header__logo-company"
              src={require("../images/logos/DALY_Study_RGB.svg")}
              alt="DALY 2 Logo"
            />
          </Link>
        </div>

        <ul className={`header__menu header__menu--${props.site}`}>
          <li
            className={`header__menu-item-clinical-trials ${
              props.site === "clinical-trials" ? "is-active" : ""
            }`}
          >
            <Link
              onClick={() => onLinkClick("The DALY 2 trials")}
              to={
                (props.i18n !== "en" ? "/" + props.i18n : "") +
                "/clinical-trial"
              }
            >
              {JSON[props.i18n].menu.clinicaltrial}
            </Link>
          </li>
          <li
            className={`header__menu-item-car-t-therapy ${
              props.site === "car-t-therapy" ? "is-active" : ""
            }`}
          >
            <Link
              onClick={() => onLinkClick("About CAR T therapy")}
              to={
                (props.i18n !== "en" ? "/" + props.i18n : "") +
                "/car-t-cell-therapy"
              }
            >
              {JSON[props.i18n].menu.celltherapy}
            </Link>
          </li>

          <li className={`header__menu-item-locations `}>
            <a href="!#" onClick={onClick}>
              {JSON[props.i18n].menu.triallocations}
            </a>
          </li>
          <li className="header__lang">
            <Language i18n={props.i18n} className="language--header" />
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
