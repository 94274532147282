import React from "react";
import JSON from "../../data/global.json";
import languages from "../../data/languages.json";

// const langFlag = {
//   de: require("../images/flags/DE.svg"),
//   nl: require("../images/flags/NL.svg"),
//   en: require("../images/flags/en.svg"),
//   it: require("../images/flags/IT.svg"),
//   fr: require("../images/flags/FR.svg"),
//   au: require("../images/flags/DE.svg"),
// };

const Language = (props) => {
  const [open, setOpen] = React.useState(false);

  function onClick() {
    setOpen(!open);
  }

  return (
    <div className={`language ${props.className} ${open ? "is-open" : ""}`}>
      <div
        onClick={onClick}
        className={`language__toggle  ${props.classNameToggle}`}
      >
        <span>
          <span>{JSON[props.i18n].languages[props.i18n]}</span>
          {/* <img alt="Active Language" src={langFlag[props.i18n]} /> */}
        </span>
        <svg
          className="world"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
        >
          <path
            className="cls-1"
            d="M46,23.7a20.8,20.8,0,0,0-2.56-9.11c-.32-.58-.66-1.15-1-1.69a21.13,21.13,0,0,0-6.6-6.2,20.8,20.8,0,0,0-21.52,0,21.13,21.13,0,0,0-6.6,6.2c-.37.54-.71,1.11-1,1.69A20.8,20.8,0,0,0,4.05,23.7c0,.33,0,.66,0,1s0,.67,0,1a20.88,20.88,0,0,0,2.56,9.12c.32.57.66,1.14,1,1.68a21.13,21.13,0,0,0,6.6,6.2,20.8,20.8,0,0,0,21.52,0,21.13,21.13,0,0,0,6.6-6.2c.37-.54.71-1.11,1-1.68A20.88,20.88,0,0,0,46,25.7c0-.33,0-.66,0-1S46,24,46,23.7Zm-2,0H36a38.79,38.79,0,0,0-.66-6.39,30.15,30.15,0,0,0,6.3-1.81A19,19,0,0,1,44,23.7ZM33.32,31.77a61,61,0,0,0-16.64,0A36.28,36.28,0,0,1,16,25.7H34A35.28,35.28,0,0,1,33.32,31.77ZM25,43.7c-3.35,0-6.34-4.1-7.89-10A58.2,58.2,0,0,1,25,33.2a57.94,57.94,0,0,1,7.88.51C31.34,39.6,28.35,43.7,25,43.7Zm-9-20a36.39,36.39,0,0,1,.66-6.07A59.6,59.6,0,0,0,25,18.2a59.71,59.71,0,0,0,8.32-.57A35.38,35.38,0,0,1,34,23.7Zm9-18c3.35,0,6.34,4.1,7.88,10A57.94,57.94,0,0,1,25,16.2a58.2,58.2,0,0,1-7.89-.51C18.66,9.8,21.65,5.7,25,5.7Zm15.54,8.1a28.22,28.22,0,0,1-5.66,1.57,21,21,0,0,0-4.3-8.83A19.06,19.06,0,0,1,40.54,13.8ZM19.42,6.54a20.9,20.9,0,0,0-4.3,8.83A28,28,0,0,1,9.46,13.8,19,19,0,0,1,19.42,6.54Zm-11,9a30.15,30.15,0,0,0,6.3,1.81A39.88,39.88,0,0,0,14,23.7h-8A18.7,18.7,0,0,1,8.39,15.5ZM6.05,25.7h8a40.13,40.13,0,0,0,.67,6.4,29.58,29.58,0,0,0-6.3,1.8A18.7,18.7,0,0,1,6.05,25.7Zm3.41,9.9A28.7,28.7,0,0,1,15.12,34a20.84,20.84,0,0,0,4.3,8.83A19,19,0,0,1,9.46,35.6Zm21.12,7.26A21,21,0,0,0,34.88,34a28.9,28.9,0,0,1,5.66,1.57A19.06,19.06,0,0,1,30.58,42.86Zm11-9a29.58,29.58,0,0,0-6.3-1.8A39,39,0,0,0,36,25.7h8A19,19,0,0,1,41.61,33.9Z"
          />
        </svg>

        <svg
          className="arrow"
          enableBackground="new 0 0 50 50"
          height="50px"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 50 50"
          width="50px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <rect fill="none" height="50" width="50" />
          <polygon points="15,2.75 12.914,4.836 33.078,25 12.914,45.164 15,47.25 37.25,25 " />
        </svg>
      </div>
      <div className={`language__list`}>
        <ul>
          {languages.map((lang, i) => {
            return (
              <li
                key={lang.short}
                className={props.i18n === lang.short ? "-isActive" : ""}
              >
                <a href={"/" + lang.url}>
                  <span>{JSON[props.i18n].languages[lang.short]}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Language;
