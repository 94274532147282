import React from "react";
import { Link } from "gatsby";
import JSON from "../../data/global.json";
import Language from "./language";

const Menu = (props) => {
  const [open, setOpen] = React.useState(false);
  // const [locationsOpen, setLocationsOpen] = React.useState(false);
  const [locationsOpen, setLocationsOpen] = React.useState(false);

  function onHamburgerClick() {
    // document.body.classList.add

    setOpen(!open);
    if (open) {
      setLocationsOpen(false);
    }
  }

  // function scrollTo(to, duration) {
  //   var start = document.documentElement.scrollTop,
  //     change = to - start,
  //     currentTime = 0,
  //     increment = 20;

  //   var animateScroll = function() {
  //     var easeInOutQuad = function(t, b, c, d) {
  //       t /= d / 2;
  //       if (t < 1) return (c / 2) * t * t + b;
  //       t--;
  //       return (-c / 2) * (t * (t - 2) - 1) + b;
  //     };
  //     currentTime += increment;
  //     var val = easeInOutQuad(currentTime, start, change, duration);
  //     document.documentElement.scrollTop = val;
  //     if (currentTime < duration) {
  //       setTimeout(animateScroll, increment);
  //     }
  //   };
  //   animateScroll();
  // }

  // function onLocationsClick(e) {
  //   e.preventDefault();
  //   setLocationsOpen(!locationsOpen);
  //   if (locationsOpen) {
  //     scrollTo(0, 250);
  //   }
  // }

  function onLocationsClick(e) {
    setLocationsOpen(!locationsOpen);
    e.preventDefault();
  }

  return (
    <div className={`menu ${open ? "is-open" : ""}`}>
      <Link
        to={(props.i18n !== "en" ? "/" + props.i18n : "") + "/"}
        className="menu__logo"
      >
        <img
          src={require("../images/logos/DALY_Study_RGB.svg")}
          alt="DALY 2 Logo"
        />
      </Link>
      <div
        className="menu__hamburger"
        onClick={onHamburgerClick}
        onKeyDown={onHamburgerClick}
        role="button"
        tabIndex={0}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={`menu__list`}>
        <li>
          <Link
            to={
              (props.i18n !== "en" ? "/" + props.i18n : "") + "/clinical-trial"
            }
            className={`${props.site === "clinical-trials" ? "is-active" : ""}`}
          >
            {JSON[props.i18n].menu.clinicaltrial}
          </Link>
        </li>
        <li>
          <Link
            to={
              (props.i18n !== "en" ? "/" + props.i18n : "") +
              "/car-t-cell-therapy"
            }
            className={`${props.site === "car-t-therapy" ? "is-active" : ""}`}
          >
            {JSON[props.i18n].menu.celltherapy}
          </Link>
        </li>
        <li
          className={`menu__toggle-locations ${
            locationsOpen ? "locations-is-open" : ""
          }`}
        >
          <a href="!#" onClick={onLocationsClick} className={""}>
            {JSON[props.i18n].menu.triallocations}
            <svg
              className=""
              enableBackground="new 0 0 50 50"
              height="50px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 50 50"
              width="50px"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect fill="none" height="50" width="50" />
              <polygon points="15,2.75 12.914,4.836 33.078,25 12.914,45.164 15,47.25 37.25,25 " />
            </svg>
          </a>
          <ul className="menu__locations">
            <li className="menu__locations-back">
              <a href="!#" onClick={onLocationsClick}>
                <span>←</span> {JSON[props.i18n].back}
              </a>
            </li>
            <li>
              <Link
                to={
                  (props.i18n !== "en" ? "/" + props.i18n : "") +
                  "/clinical-trial/eu"
                }
                className={`${
                  props.site === "clinical-trials-eu" ? "is-active" : ""
                }`}
              >
                {JSON[props.i18n].menu.clinicaleu}
              </Link>
            </li>
            <li>
              <Link
                to={
                  (props.i18n !== "en" ? "/" + props.i18n : "") +
                  "/clinical-trial/us"
                }
                className={`${
                  props.site === "clinical-trials-us" ? "is-active" : ""
                }`}
              >
                {JSON[props.i18n].menu.clinicalus}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Language i18n={props.i18n} />
        </li>
      </ul>
    </div>
  );
};

export default Menu;
