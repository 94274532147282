import React, { Component } from "react";
class Readmore extends Component {
  static defaultProps = {
    allowMultipleOpen: false,
  };

  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  onClick = (label) => {
    const {
      state: { isOpen },
    } = this;

    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const {
      onClick,
      props: { children, className, classNameToggle, showLess, showMore },
      state: { isOpen },
    } = this;

    return (
      <div className={`readmore ${className}`}>
        <div className={`readmore__content ${isOpen ? "is-open" : ""}`}>
          {children}
        </div>
        <div
          onClick={onClick}
          onKeyDown={onClick}
          className={`readmore__toggle  ${classNameToggle}`}
          role="button"
          tabIndex={0}
        >
          {isOpen ? showLess : showMore}
        </div>
      </div>
    );
  }
}

export default Readmore;
